import baseTheme from '@lekoarts/gatsby-theme-cara/src/gatsby-plugin-theme-ui'
import { tailwind } from "@theme-ui/presets"

const theme = {
  ...baseTheme,
    config: {
      useCustomProperties: true,
      useColorSchemeMediaQuery: 'system',
      useLocalStorage: false,
    },
  colors: {
      ...baseTheme.colors,
      modes: {
        light: {
          ...baseTheme.colors.modes.light,
          icon_pink: tailwind.colors.pink[3],
        }
      }
    }
}

export default theme